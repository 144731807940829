import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export const Wellcome = () => {
  return (
    <div className="w-75">
      <div className="mt-3">
        <h1 className="text-center">Welcome to our parish</h1>
        <p className="mt-3 text-justify" style={{ textAlign: "justify" }}>
          St. Moses the Strong Coptic Orthodox Church in Geelong, Victoria, is
          part of the Coptic Orthodox Diocese of Melbourne, which is headed by
          His Holiness Pope Tawadros II. We endeavour to share the Orthodox
          Christian message of our Lord Jesus Christ that He taught, the
          Apostles preached, and the fathers kept over more than
          two-thousand-year history of the Church. It is a community parish open
          and welcoming to all and includes services for families and children.
          Our aim is to bring everyone into a deeper life in Christ through the
          life of the church. We are slowly building our website so stay tuned
          for new content. For more information, please contact us!
        </p>
      </div>
      <Tabs
        defaultActiveKey="Pope"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="Pope" title="Our Pope">
          <div style={{ textAlign: "justify" }}>
            <h3 className="tabTitle">HH Pope Tawadros II </h3>
            <p>
              <strong>HH Pope Tawadros II</strong> was born as Wagih Subhi Baqi
              Soliman on November 4th, 1952 in Mansoura, Egypt. His father was
              an irrigation engineer and his family moved around during his
              childhood from Mansoura to Sohag and then to Damanhour. He
              received his bachelor’s degree in pharmacy in 1975 from Alexandria
              University and earned a fellowship for the World Health
              Organization from the British International Health Institute in
              England in 1985.
            </p>
            <p>
              He attended the Coptic Seminary and graduated in 1983. He then
              worked as a manager in a pharmaceutical company in Damanhour that
              was owned by the Ministry of Health. His Holiness’s life has
              always revolved around church since his youth; he wished to live
              the life of monasticism. He entered the Monastery of St. Bishoy in
              Wadi El-Natroun on August 20th, 1986 and remained a brother for
              two years. He was ordained a monk on July 31, 1988 and after a
              year he was ordained a priest on December 23, 1989. Two months
              after, H.H. Pope Tawadros started serving with H.E. Metropolitan
              Pakhomius of Behira on February 15th, 1990. He was ordained a
              bishop on June 15th, 1997 by H.H. the Late Pope Shenouda III as a
              General Bishop assisting H.E. Metropolitan Pakhomius. His Holiness
              focused on childhood whether it was in the country-wide children’s
              festival as well when he was in charge of the children’s committee
              in the Holy Synod. Before assuming the papacy, H.H. wrote twelve
              books.
            </p>
          </div>
        </Tab>
        <Tab eventKey="Church" title="Our church">
          <div style={{ textAlign: "justify" }}>
            <h3 className="tabTitle">Our Church </h3>
            <p>
              <strong>The Coptic Orthodox Church</strong> is one of the five
              most ancient churches in the world and is called the “See of Saint
              Mark”. The other four ancient sees are the Church of Jerusalem,
              the Church of Antioch (Antiochian Orthodox Church), the Church of
              Rome (Roman Catholic Church) and the Church of Athens (Greek
              Orthodox Church).
            </p>
            <p>
              The church adheres to the Nicene Creed.{" "}
              <strong>St. Athanasius (296-373 A.D.)</strong>, the twentieth Pope
              of the Coptic Church effectively defended the Doctrine of the Lord
              Jesus Christ's Divinity at the Council of Nicaea in AD 325. His
              affirmation of the doctrine earned him the title;{" "}
              <strong>"Father of Orthodoxy"</strong> and{" "}
              <strong>St. Athanasius "the Apostolic"</strong>.{" "}
            </p>
            <p>
              The term "Coptic" is derived from the Greek "Aigyptos" meaning
              "Egyptian". When the Arabs arrived in Egypt in the seventh
              century, they called the Egyptians "Qibt". Thus, the Arabic word
              "Qibt" came to mean both "Egyptians" and "Christians". The term
              "Orthodoxy" here refers to the preservation of the "Original
              Faith" by the Copts who, throughout the ages, defended the Old
              Creed against the numerous attacks aimed at it. Saint Mark the
              Apostle and Evangelist, one of the seventy apostles and writer of
              the oldest gospel, established the Church of Alexandria in about
              55 AD. In Alexandria, St. Mark structured the church worship;
              ordained one bishop and seven deacons; established the famous
              School of Alexandria; and was martyred there by the Romans in 68
              AD. It was at the house of Saint Mark that the Lord met with His
              Apostles and celebrated the Passover, where the Lord appeared to
              them after His Resurrection, and where the Holy Spirit descended
              upon the Apostles on the Day of Pentecost. Thus, it is well known
              by all the Apostolic Churches as the first church in the world.
              Hence, the Coptic Church is one of the oldest churches in the
              world, spanning 20 centuries of history.
            </p>
          </div>
        </Tab>
        <Tab eventKey="Faith" title="Our Faith">
          <div style={{ textAlign: "justify" }}>
            <h3 className="tabTitle">The Orthodox Creed</h3>
            <p>
              "We believe in one God, God the Father the Pantocrator who created
              heaven and earth, and all things seen and unseen.
            </p>
            <p>
              We believe in one Lord Jesus Christ, the Only-Begotten Son of God,
              begotten of the Father before all ages; Light of Light, true God
              of true God, begotten not created, of one essence with the Father,
              by whom all things were made; Who for us men and for our salvation
              came down from heaven, and was incarnate of the Holy spirit and
              the Virgin Mary and became Man. And He was crucified for us under
              Pontius Pilate, suffered and was buried. And on the third day He
              rose from the dead, according to the scriptures, ascended to the
              heavens; He sits at the right hand of his Father, and He is coming
              again in His glory to judge the living and the dead, Whose kingdom
              shall have no end.
            </p>

            <p>
              Yes, we believe in the Holy Spirit, the Lord, the Life-Giver, Who
              proceeds from the Father, Who with the Father and the Son is
              worshipped and glorified, who spoke by the prophets.
            </p>

            <p>
              And in one holy, catholic and apostolic church. We confess one
              baptism for the remission of sins. We look for the resurrection of
              the dead, and the life of the coming age. Amen."
            </p>
          </div>
        </Tab>
        <Tab eventKey="Our Patron" title="Our Patron">
          <div style={{ textAlign: "justify" }}>
            <h3 className="tabTitle">St Moses the Strong</h3>
            <p>
              <strong>St. Moses the Strong</strong> (also Known as the Black),
              whose life story is remarkable. This saint took the Kingdom of
              Heaven by force, exactly as our Lord Jesus Christ said: The
              Kingdom of Heaven suffers violence, and the violent take it by
              force. (Matthew 11:12). In his early life, St. Moses was a slave
              to people who worshiped the sun. He was a mighty man who loved to
              eat and drink excessively. He killed, robbed and committed all
              evil. No one could stand up before him, or challenge him. On many
              occasions, he lifted up his eyes to look to the sun and to talk to
              it saying, O Sun!! if you are God, let me know it. Then he said,
              and you O God whom I do not know, let me know you. One day, he
              heard someone saying to him, the monks of Wadi El-Natroun know the
              real God. Go to them and they will tell you. Instantly, he rose
              up, girded his sword and went to the wilderness of Shiheat. He met
              St. Esidorous (Isidore) the priest, who was frightened when he saw
              him, because of his appearance. St. Moses comforted him by saying
              that he came to the monks so that they might let him know the real
              God. St. Esidorous took him to St. Macarius the Great, who
              preached to him, taught him the faith and baptized him. He
              accepted St. Moses as a monk and taught him to live in the
              wilderness. St. Moses dashed in many worships, and fought a
              spiritual fight which was greater than that fought by many saints.
              However, the devil fought him intensively with his old habits of
              excessive eating, drinking, and fornication. He informed St.
              Esidorous about everything which came upon him in his fight with
              the Enemy. He comforted him and taught him how to overcome the
              snares of the devil. It was told about him, that when the elders
              of the Monastery slept, he used to go round to their cells and
              take their water pots and fill them with water which he brought
              from a well at a far distance from the monastery. After many years
              in spiritual struggle, the devil envied him, and struck him with a
              sore on his foot which made him sick and bed-ridden. When he knew
              that this was from the devil, he increased in his asceticism and
              worship, until his body became as a burnt wood. God looked to his
              patience, healed his illness, and removed all his pains. The
              blessing of the Lord came upon him. After a while, he became the
              father and the spiritual guide of 500 brothers, who elected him to
              be ordained a priest. When he came before the Patriarch to be
              ordained, the patriarch wanted to test him by asking the elders,
              Who brought this black here? Cast him out. He obeyed, and left
              saying to himself, It is good what they have done to you, O black
              coloured one. The Patriarch, however, called him back and ordained
              him a priest, and said to him, Moses, all of you now has become
              white.
            </p>
            <p>
              One day, he went with some elders to St. Macarius the Great, who
              said to them, I see among you one to whom belong the crown of
              martyrdom. St. Moses answered him, probably it is me, for it is
              written: 'For all they that take with the sword, shall perish with
              the sword.' (Matt. 26:25) After they returned to the monastery, it
              did not take long until the Barbarians attacked the monastery. He
              told the brethren, whoever wants to escape, let him escape. They
              asked him: And you O father, why do you not also escape? He
              replied that he had waited for this day for long time. The
              Barbarians entered the monastery and killed him with seven other
              brothers. One of the brethren was hiding, and saw the angel of the
              Lord, with a crown in his hand standing by and waiting for him. He
              went out from his hiding place to the Barbarians and he was also
              martyred on 24th of Paoni. May his prayers be with us, and glory
              be to God forever. Amen. Beloved Ones, contemplate in the power of
              repentance, and what it did. It transformed an infidel slave who
              was a murderer, adulterer and robber into a great Father, teacher,
              comforter, and priest who wrote rules for the monks, and saint
              whose name is mentioned on the altar in our prayers. His Body is
              located now in the Monastery of Al-Baramus. Our church
              commemorates the Great Saint St Moses the Strong on Paoni 24th of
              the Coptic calendar which is 1st of July.
            </p>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};
